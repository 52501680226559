import { navigate } from 'gatsby'
import { not } from 'ramda'
import React, { useEffect, useState } from 'react'
import { IconContext } from 'react-icons'
import { FaBars } from 'react-icons/fa'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'
import Hat from '../Hat/'
import Sidebar from '../Sidebar/'

const Menu = styled.div`
  background-color: ${({ opacity }) => grapeGray(0, opacity)};
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  position: fixed;
  right: 2rem;
  top: 1rem;
  width: 8rem;
  height: 2.5rem;
  z-index: 100;
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`

const StyledHeader = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: ${white()};
  z-index: 1000000;

  .fa-bars {
    position: fixed;
    top: 1.5rem;
    right: 2.5rem;
    color: ${white()};
    zindex: 120;
    display: ${({ open }) => (open ? 'none' : 'block')};
    cursor: pointer;
  }

  .logo-hat {
    position: absolute;
    top: -0.3rem;
    left: 0.35rem;
  }
`
StyledHeader.displayName = 'StyledHeader'

const Label = styled.span`
  position: fixed;
  color: ${white()};
  top: 1.6rem;
  right: 4.5rem;
  font-size: 1.4rem;
  font-family: 'Brandon Grotesque Light', Verdana, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  z-index: 110;
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  cursor: pointer;

  &:hover {
    color: ${white(0, 0.5)};
  }
`

const Logo = styled.div`
  background-color: ${({ opacity }) => grapeGray(0, opacity)};
  position: fixed;
  left: 2rem;
  top: 1rem;
  width: 4rem;
  height: 2.5rem;
  cursor: pointer;
`

const round = num => Math.ceil(num * 10) / 10

const getOpacity = () => {
  const distance = round(window.scrollY / window.innerHeight)

  return distance > 0.8 ? 0.8 : distance
}

const getColor = opaqueness => moonYellow(48 - opaqueness * 60)

const toHome = () => navigate('/')

export default function Header () {
  const [open, setOpen] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const [color, setColor] = useState(white())

  const toggle = () => setOpen(not(open))

  useEffect(() => {
    const handleScroll = () => {
      const opaqueness = getOpacity()

      if (opacity !== opaqueness) {
        setOpacity(opaqueness)
        setColor(getColor(opaqueness))
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [color, opacity])

  return (
    <StyledHeader id='home'>
      <Logo opacity={opacity} onClick={toHome}>
        <Hat size={50} fill={color} className='logo-hat' />
      </Logo>
      <Menu onClick={toggle} opacity={opacity} hide={open}>
        <Label onClick={toggle} hide={open}>
          Menu
        </Label>
        <IconContext.Provider value={{ size: '1.5rem' }}>
          <FaBars onClick={toggle} className='fa-bars' />
        </IconContext.Provider>
      </Menu>
      <Sidebar open={open} toggle={toggle} />
    </StyledHeader>
  )
}
