import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  position: absolute;
  top: -1000px;
  left: -1000px;
`

export default function Beehive ({ onChange }) {
  return (
    <Input
      type='text'
      title='Leave this empty'
      name='beehive'
      id='beehive'
      onChange={onChange}
    />
  )
}
