import React, { useState } from 'react'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'
import encodeData from '../../utils/encodeData'
import Beehive from '../Beehive/'

const Form = styled.form`
  display: flex;
  background-color: ${grapeGray()};
  margin: 1rem 0;
`

const Thanks = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${grapeGray()};
  margin: 1rem 0;
  align-items: flex-start;
`

const Button = styled.button`
  outline: 0;
  border: none;
  font-family: 'Raleway Medium', Verdana, sans-serif;
  font-size: 0.8rem;
  color: ${white()};
  flex-grow: 1;
  margin: 0.25rem 0;
  text-transform: uppercase;
  border: 1px solid ${white()};
  background-color: ${grapeGray()};
  padding: 0.5rem;

  &:hover,
  &:focus {
    border-color: ${moonYellow()};
    color: ${moonYellow()};
    box-shadow: 3px 2px 7px ${grapeGray(40)};
  }
`

const Message = styled.p`
  font-family: 'Raleway Regular', Verdana, sans-serif;
  color: ${white()};
  flex-grow: 1;
  margin: 1rem 0;
  text-align: left;
`

const EmailInput = styled.input`
  border: none;
  outline: 0;
  background-color: ${grapeGray(20)};
  color: ${white()};
  padding: 0.7rem 0.5rem;
  font-size: 1.1rem;
  flex-grow: 1;
  flex-shrink: 0;
`

const SubmitButton = styled.button`
  border: none;
  color: ${grapeGray()};
  background-color: ${moonYellow()};
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0 0.5rem;
`

const formDefault = {
  'form-name': 'signup',
  email: '',
  beehive: ''
}

export default function NotifyForm () {
  const [state, setState] = useState(formDefault)
  const [formSent, setFormSent] = useState(false)

  const handleChange = ({ target: { name, value } = {} }) => {
    setState({ ...state, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeData(state)
    })
      .then(() => {
        setFormSent(true)
        setState(formDefault)
      })
      .catch(error => alert(error))
  }

  return formSent ? (
    <Thanks>
      <Message>Thanks! We'll send you a confirmation email shortly.</Message>
      <Button onClick={() => setFormSent(false)}>Add another email</Button>
    </Thanks>
  ) : (
    <Form
      name='signup'
      method='POST'
      netlify-honeypot='beehive'
      data-netlify='true'
      onSubmit={handleSubmit}
    >
      <EmailInput
        id='email'
        type='email'
        name='email'
        required
        onChange={handleChange}
      />
      <SubmitButton>Send</SubmitButton>
      <Beehive onChange={handleChange} />
      <input type='hidden' name='form-name' value='signup' />
    </Form>
  )
}
