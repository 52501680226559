import React from 'react'
import styled from 'styled-components'

import { white } from '../../constants'

const Section = styled.section`
  height: 100vh;
  overflow: auto;
  padding: 6rem 2rem;
  width: 100%;
  font-family: 'Raleway Medium', Verdana, sans-serif;
  font-size: 4vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${white()};
  background-image: url(/images/mountain-3000.jpeg);
  background-color: hsla(48, 87%, 42%, 1);
  background-repeat: no-repeat;
  background-position: center 30%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  width: 100vw;
  line-height: 1.4;
  letter-spacing: 0.3rem;

  @media (min-width: 768px) {
    background-attachment: fixed;
  }

  @media (max-width: 2600px) {
    background-image: url(/images/mountain-2600.jpeg);
  }

  @media (max-width: 2200px) {
    background-image: url(/images/mountain-2200.jpeg);
  }

  @media (max-width: 1800px) {
    background-image: url(/images/mountain-1800.jpeg);
  }

  @media (max-width: 1400px) {
    background-image: url(/images/mountain-1400.jpeg);
  }

  @media (max-width: 1000px) {
    background-image: url(/images/mountain-1000.jpeg);
  }

  @media (max-width: 800px) {
    background-image: url(/images/mountain-800.jpeg);
  }
`

const Jit = styled.div`
  font-family: 'Raleway Bold', Verdana, sans-serif;
  font-size: 10vw;
  letter-spacing: 0;
`

export default function Hero () {
  return (
    <Section>
      THE FUTURE IS
      <Jit>just-in-time</Jit>
    </Section>
  )
}
