import React from 'react'
import { FaLinkedin, FaTwitter } from 'react-icons/fa'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'
import NotifyForm from '../NotifyForm/'

const StyledFooter = styled.footer`
  font-family: 'Raleway Regular', Verdana, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: ${grapeGray()};
  color: ${white()};
  padding: 0 7.4rem;
  min-height: 100vh;

  @media (max-width: 720px) {
    padding: 0.5rem 2rem;
  }

  .follow-links {
    margin-right: 0.3rem;
    vertical-align: middle;
  }
`
StyledFooter.displayName = 'StyledFooter'

const TopRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`

const BottomRow = styled.div`
  font-size: 0.85rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 7rem;
  margin-bottom: 1rem;

  @media (max-width: 720px) {
    font-size: 0.7rem;
  }
`

const LogoRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0;
`

const AboutBox = styled.div`
  flex-grow: 3;
  flex-shrink: 0;
  padding-right: 1rem;
  line-height: 1.4;
  max-width: 50%;

  @media (max-width: 720px) {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const FollowBox = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 1rem;
  padding-left: 1rem;
  line-height: 1.4;

  @media (max-width: 720px) {
    width: 100%;
    padding: 0;
  }
`

const NotifyBox = styled.div`
  flex-basis: auto;
  flex-grow: 2;
  flex-shrink: 1;
  padding-left: 1rem;
  line-height: 1.4;
  max-width: 33%;

  @media (max-width: 720px) {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const Title = styled.h2`
  font-family: 'Brandon Grotesque Regular', Verdana, sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${white()};
  line-height: 6;
  border-bottom: 1px solid ${grapeGray(10)};
  margin-bottom: 3rem;

  @media (max-width: 720px) {
    line-height: 3;
    margin: 3rem 0 1rem;
  }
`

const CopyrightBox = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  width: 50%;
  padding-right: 1rem;
  line-height: 1.4;
  text-align: right;
  border-right: 1px solid ${grapeGray(10)};

  @media (max-width: 720px) {
    padding-right: 0.5rem;
  }
`

const CreditBox = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  width: 50%;
  padding-left: 1rem;
  line-height: 1.4;

  @media (max-width: 720px) {
    padding-left: 0.5rem;
  }
`

const Link = styled.a`
  display: block;
  color: ${white()};
  line-height: 1.5rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${moonYellow()};
  }
`

export default function Footer () {
  return (
    <StyledFooter id='footer'>
      <TopRow>
        <AboutBox>
          <Title>Paperhat</Title>A paper hat is adaptable and versatile: it can
          be refolded to any size or shape. Paper hats are lightweight, fun, and
          functional. They are bespoke. Paper hats are reusable, repurposable,
          recyclable. At Paperhat, we wear many hats, and what we like most of
          all about them is this: paper hats are unpretentious. And that&lsquo;s
          important to us because we think that learning should be enjoyable,
          invigorating, and, most of all, productive.
        </AboutBox>
        <FollowBox>
          <Title>Follow</Title>
          <Link href='https://www.linkedin.com/company/paperhat-ltd/'>
            <FaLinkedin className='follow-links' /> LinkedIn
          </Link>
          <Link href='https://twitter.com/PaperhatLtd'>
            <FaTwitter className='follow-links' /> Twitter
          </Link>
        </FollowBox>
        <NotifyBox>
          <Title>Stay updated</Title>
          Join our mailing list and we&lsquo;ll notify you whenever we add new
          material to this site. Low traffic; no spam: we promise!
          <NotifyForm />
        </NotifyBox>
      </TopRow>
      <BottomRow>
        <CopyrightBox>&copy; 2019 Paperhat, Ltd.</CopyrightBox>
        <CreditBox>design by Munat, Ltd.</CreditBox>
      </BottomRow>
      <LogoRow>
        <a href='https://gatsbyjs.org/'>
          <img src='/images/gatsby-logo-sm.png' alt='GatsbyJS' />
        </a>
      </LogoRow>
    </StyledFooter>
  )
}
