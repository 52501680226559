import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'Brandon Grotesque Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Regular'), url('/fonts/Brandon_reg.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Regular Italic'), url('/fonts/Brandon_reg_it.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Thin'), url('/fonts/Brandon_thin.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Thin Italic'), url('/fonts/Brandon_thin_it.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Light';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Light'), url('/fonts/Brandon_light.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Light Italic'), url('/fonts/Brandon_light_it.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Medium'), url('/fonts/Brandon_med.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Medium Italic'), url('/fonts/Brandon_med_it.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Bold'), url('/fonts/Brandon_bld.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Bold Italic'), url('/fonts/Brandon_bld_it.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Black';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Black'), url('/fonts/Brandon_blk.woff') format('woff');
  }

  @font-face {
    font-family: 'Brandon Grotesque Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Brandon Grotesque Black Italic'), url('/fonts/Brandon_blk_it.woff') format('woff');
  }

  @font-face {
    font-family: 'Raleway Black';
    src: url('/fonts/raleway-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Black Italic';
    src: url('/fonts/raleway-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Bold';
    src: url('/fonts/raleway-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Bold Italic';
    src: url('/fonts/raleway-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Extra Bold';
    src: url('/fonts/raleway-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Extra Bold Italic';
    src: url('/fonts/raleway-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Extra Light';
    src: url('/fonts/raleway-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Extra Light Italic';
    src: url('/fonts/raleway-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Italic';
    src: url('/fonts/raleway-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Italic';
    src: url('/fonts/raleway-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Light Italic';
    src: url('/fonts/raleway-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Medium';
    src: url('/fonts/raleway-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Medium Italic';
    src: url('/fonts/raleway-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Regular';
    src: url('/fonts/raleway-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Semibold';
    src: url('/fonts/raleway-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Semibold Italic';
    src: url('/fonts/raleway-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Thin';
    src: url('/fonts/raleway-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Raleway Thin Italic';
    src: url('/fonts/raleway-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html, body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway Regular', Verdana, sans-serif;
  }

  p {
    margin: 0 0 1rem;
  }
`

export default GlobalStyle
