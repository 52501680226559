import React from 'react'

import { white } from '../../constants'

export default function Hat ({ className, fill = white(), size = 80 }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 955.000000 955.000000'
      preserveAspectRatio='xMidYMid meet'
      className={className}
    >
      <g
        transform='translate(0.000000,955.000000) scale(0.100000,-0.100000)'
        fill={fill}
        stroke='none'
      >
        <path
          d='M3850 5707 c-476 -670 -866 -1224 -868 -1231 -2 -10 1727 -690 1735
-682 2 1 2 707 1 1568 l-3 1564 -865 -1219z'
        />
        <path
          d='M4910 5360 c0 -1254 3 -1571 13 -1567 578 222 1727 674 1727 680 0 6
-1499 2126 -1712 2421 l-28 39 0 -1573z'
        />
        <path
          d='M618 5097 c31 -46 403 -590 827 -1208 l769 -1125 126 46 c1811 676
2259 845 2253 851 -5 3 -883 335 -1953 737 -1070 402 -1975 742 -2012 756
l-68 26 58 -83z'
        />
        <path
          d='M5690 3899 c-1812 -681 -3311 -1246 -3330 -1254 -31 -12 274 -14
2456 -14 l2492 -1 842 1251 c462 688 840 1252 838 1254 -2 2 -1486 -554 -3298
-1236z'
        />
      </g>
    </svg>
  )
}
