export const black = (shift = 0, alpha = 1) =>
  `hsla(0, 0%, ${0 + shift}%, ${alpha})`
export const grapeGray = (shift = 0, alpha = 1) =>
  `hsla(270, 2%, ${25 + shift}%, ${alpha})`
export const mischkaGray = (shift = 0, alpha = 1) =>
  `hsla(216, 3%, ${66 + shift}%, ${alpha})`
export const moonYellow = (shift = 0, alpha = 1) =>
  `hsla(48, 87%, ${52 + shift}%, ${alpha})`
export const white = (shift = 0, alpha = 1) =>
  `hsla(0, 0%, ${100 + shift}%, ${alpha})`
