import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'

const toMethodology = () => navigate('/#methodology')

const Section = styled.section`
  font-family: 'Raleway Regular', Verdana, sans-serif;
  width: 100%;
  background-color: ${white};
  padding: 2rem 7.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;

  @media (max-width: 720px) {
    padding: 0.5rem 3rem;
    text-align: left;
  }
`

const Title = styled.h2`
  font-family: 'Brandon Grotesque Medium', Verdana, sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${moonYellow()};
  margin-bottom: 3rem;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 720px) {
    line-height: 3;
    margin: 3rem 0 1rem;
  }
`

const Description = styled.div`
  font-size: 1.5rem;
  color: ${grapeGray(10)};
  text-align: center;
  flex-grow: 1;
  line-height: 1.3;

  @media (max-width: 720px) {
    font-size: 1.5rem;
    text-align: left;
  }
`

const Link = styled.span`
  color: ${moonYellow()};
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: ${moonYellow(30)};
  }
`

const Language = styled.p`
  font-size: 1rem;
  text-transform: uppercase;
  font-family: 'Raleway Medium', Verdana, sans-serif;
  margin-bottom: 2rem;
`

export default function About () {
  return (
    <Section id='about'>
      <Title>Who we are</Title>
      <Description>
        <p>
          PAPERHAT is a small team of individuals with deep experience in
          education, leadership, management, business and systems analysis,
          software architecture, development, and more.
        </p>
        <p>
          <Link onClick={toMethodology}>The Paperhat Methodology&trade;</Link>{' '}
          is a toolkit for learning as rapidly as possible with minimum effort
          and maximum enjoyment. Our lead researcher, Charles Munat, has been
          applying the methodology for more than two decades, personally and in
          the enterprise, continually improving it with each new iteration.
        </p>
      </Description>
      <Language>Plain English Spoken Here</Language>
    </Section>
  )
}
