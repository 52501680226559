import React from 'react'

import About from '../About'
import Contact from '../Contact'
import Hero from '../Hero'
import Methodology from '../Methodology'
import Services from '../Services'

export default function Home () {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <Methodology />
      <Contact />
    </>
  )
}
