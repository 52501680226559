import React from 'react'
import styled from 'styled-components'

import { grapeGray, mischkaGray, moonYellow, white } from '../../constants'

const Section = styled.section`
  width: 100%;
  background-color: ${white()};
  padding: 2rem 7.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  @media (max-width: 1028px) {
    padding: 0.5rem 3rem;
  }

  @media (max-width: 639px) {
    padding: 0.5rem 1rem;
  }
`

const Title = styled.h2`
  font-family: 'Brandon Grotesque Medium', Verdana, sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${moonYellow()};
  margin-bottom: 3rem;
  flex-grow: 1;
  line-height: 6;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 720px) {
    line-height: 3;
    margin: 3rem 0 1rem;
  }
`

const Item = styled.div`
  text-transform: lowercase;
  display: inline-block;
  padding: 0 0.5rem;
  color: ${({ color = grapeGray() }) => color};
  font-size: 2rem;
  line-height: 1.5;

  @media (max-width: 1028px) {
    font-size: 1.5rem;
  }

  @media (max-width: 400px) {
    display: block;
  }
`

const Items = styled.div`
  color: ${grapeGray(10)};
  text-align: center;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  @media (max-width: 400px) {
    flex-wrap: nowrap;
  }
`

export default function Methodology () {
  return (
    <Section id='methodology'>
      <Title>Our methodology</Title>
      <Items>
        <Item>Just-in-time</Item>
        <Item color={mischkaGray()}>Cognitive Footprint</Item>
        <Item>Keep It Real</Item>
        <Item color={mischkaGray()}>Bottom-up</Item>
        <Item>Single Shortest Path</Item>
        <Item color={mischkaGray()}>Contextualize</Item>
        <Item>Cultivate Autonomy</Item>
        <Item color={mischkaGray()}>Front-to-back</Item>
        <Item>Make It to Measure</Item>
        <Item color={mischkaGray()}>Outside-in</Item>
        <Item>Skills before Knowledge</Item>
        <Item color={mischkaGray()}>Embrace the Zone</Item>
        <Item>Everything Is Contingent</Item>
        <Item color={mischkaGray()}>Favor Teachers</Item>
        <Item>Surface the Pedagogy</Item>
        <Item color={mischkaGray()}>Be Agile</Item>
        <Item>Student-first</Item>
        <Item color={mischkaGray()}>Hack Everything</Item>
      </Items>
    </Section>
  )
}
