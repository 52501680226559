import React, { useState } from 'react'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'
import encodeData from '../../utils/encodeData'
import Beehive from '../Beehive/'
import Thanks from '../Thanks/'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 2rem auto;
  width: 100%;
  align-items: stretch;
  justify-content: center;

  @media (min-width: 36rem) {
    align-items: center;
    justify-content: center;
    max-width: 42rem;
  }
`

const Row = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  padding: 0.25rem 0;

  @media (min-width: 36rem) {
    max-width: 36rem;
  }

  @media (max-width: 900px) {
    margin: 0;
  }
`

const Input = styled.input`
  border: 1px solid ${grapeGray(50)};
  outline: 0;
  background-color: ${white(-8)};
  color: ${grapeGray()};
  padding: 0.7rem 0.5rem;
  font-size: 1.1rem;
  margin: 0.25rem 0;
  width: 100%;

  @media (min-width: 900px) {
    margin: 0;
  }

  &:focus {
    background-color: ${white()};
  }
`

const Label = styled.label`
  line-height: 1.5;
  font-size: 1.2rem;
  display: block;
  padding: 0.5rem 0 0;

  @media (min-width: 900px) {
    line-height: 2;
    position: absolute;
    display: inline-block;
    padding: 0.5rem;
    width: 10rem;
    left: -10rem;
    top: 0.2rem;
    text-align: right;
  }
`

const SubmitButton = styled.button`
  border: 1px solid ${moonYellow()};
  color: ${moonYellow()};
  background-color: ${grapeGray()};
  padding: 1rem;
  outline: 0;

  &:hover,
  &:focus {
    border-color: ${white()};
    color: ${white()};
    box-shadow: 3px 2px 7px ${grapeGray(20)};
  }
`

const MessageBox = styled.textarea`
  border: 1px solid ${grapeGray(50)};
  outline: 0;
  background-color: ${white(-8)};
  color: ${grapeGray()};
  padding: 0.7rem 0.5rem;
  font-size: 1.1rem;
  min-height: 8rem;
  width: 100%;

  &:focus {
    background-color: ${white()};
  }
`

const formDefault = {
  'form-name': 'contact',
  name: '',
  emailAddress: '',
  location: '',
  message: '',
  beehive: ''
}

export default function ContactForm () {
  const [state, setState] = useState(formDefault)
  const [formSent, setFormSent] = useState(false)

  const handleChange = ({ target: { name, value } = {} }) => {
    setState({ ...state, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeData(state)
    })
      .then(() => {
        setFormSent(true)
        setState(formDefault)
      })
      .catch(error => alert(error))
  }

  return formSent ? (
    <Thanks resetForm={() => setFormSent(false)} />
  ) : (
    <Form
      name='contact'
      method='POST'
      netlify-honeypot='beehive'
      data-netlify='true'
      onSubmit={handleSubmit}
    >
      <Row>
        <Label htmlFor='name'>Name</Label>
        <Input id='name' type='text' name='name' onChange={handleChange} />
      </Row>
      <Row>
        <Label htmlFor='emailAddress'>Email address*</Label>
        <Input
          id='emailAddress'
          type='email'
          name='emailAddress'
          required
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Label htmlFor='location'>Location</Label>
        <Input
          id='location'
          type='text'
          name='location'
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Label htmlFor='message'>Message*</Label>
        <MessageBox
          id='message'
          name='message'
          required
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Label />
        <SubmitButton>Send Message</SubmitButton>
        <Beehive onChange={handleChange} />
        <input type='hidden' name='form-name' value='contact' />
      </Row>
    </Form>
  )
}
