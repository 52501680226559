import React from 'react'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'
import ContactForm from '../ContactForm/'

const Title = styled.h2`
  font-family: 'Brandon Grotesque Medium', Verdana, sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${white()};
  margin: 3rem 0;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 720px) {
    line-height: 3;
    margin: 3rem 0 1rem;
  }
`

const Description = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.2rem;
  color: ${grapeGray()};
  text-align: left;
  flex-grow: 1;
  max-width: 36rem;
  margin: 0 auto;
  line-height: 1.5;

  @media (max-width: 720px) {
    font-size: 1rem;
  }
`

const Section = styled.section`
  width: 100%;
  background-color: ${moonYellow()};
  padding: 2rem 7.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;

  @media (max-width: 720px) {
    padding: 0.5rem 2rem;
  }
`

export default function Contact () {
  return (
    <Section id='contact'>
      <Title>Contact us</Title>
      <Description>
        We love to talk shop! Feel free to contact us with thoughts or questions
        about our methodology and how we use it. Or inquire about our services.
        We&lsquo;ll do our best to get back to you as quickly as possible.
      </Description>
      <ContactForm />
    </Section>
  )
}
