import styled from 'styled-components'

import { grapeGray, moonYellow } from '../../constants'

const Icon = styled.div`
  width: 100%;
  height: 10rem;
  background-color: ${grapeGray()};
  color: ${moonYellow()};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Icon
