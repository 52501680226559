import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'

const SideMenu = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  right: ${({ open }) => (open ? '0' : '-18rem')};
  background: ${grapeGray()};
  color: white;
  min-width: 16rem;
  max-width: 90vw;
  padding: 0;
  transition: right 0.75s;
  transform-style: preserve-3d;
  border-left: 1px solid ${grapeGray(-5)};
  box-shadow: -3px 0 10px ${grapeGray(-10)};
  z-index: 1000;
`

const CloseButton = styled.div`
  position: absolute;
  top: 1.45rem;
  right: 1.5rem;
  font-family: 'Brandon Grotesque Light', Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  color: ${white()};
  cursor: pointer;
  z-index: 1000;

  &:hover {
    color: ${moonYellow()};
  }
`

const Logo = styled.h1`
  color: ${white()};
  font-family: 'Brandon Grotesque Light', Verdana, sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.3em;
  padding: 1.5rem;
  flex-grow: 1;
`

const Menu = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-grow: 3;
`

const MenuItem = styled.li`
  margin: 0;
  padding: 0.5rem 0;
  font-family: 'Raleway Thin', Verdana, sans-serif;
  font-size: 1.1rem;
  line-height: 2.5;
  letter-spacing: 0.05rem;
  cursor: pointer;
  padding: 0 1.5rem;

  &:hover {
    color: ${moonYellow()};
    background-color: ${grapeGray(-5)};
  }
`

const Pitch = styled.div`
  font-family: 'Raleway Thin', Verdana, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 1.5rem 1.7rem 1.5rem 1.5rem;
  width: 16rem;
  max-width: 95vw;
  line-height: 1.5;
  flex-grow: 3;
`

const Link = styled.span`
  color: ${moonYellow()};
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: ${moonYellow(30)};
  }
`

const toAbout = () => navigate('/#about')
const toContact = () => navigate('/#contact')
const toFooter = () => navigate('/#footer')
const toHome = () => navigate('/')
const toMethodology = () => navigate('/#methodology')
const toServices = () => navigate('/#services')

export default function Sidebar ({ open, toggle }) {
  return (
    <SideMenu open={open}>
      <Logo>
        PAPERHAT
        <CloseButton title='Close' onClick={toggle}>
          ×
        </CloseButton>
      </Logo>
      <Menu>
        <MenuItem onClick={toHome}>Home</MenuItem>
        <MenuItem onClick={toAbout}>About us</MenuItem>
        <MenuItem onClick={toServices}>Our services</MenuItem>
        <MenuItem onClick={toMethodology}>Our methodology</MenuItem>
        <MenuItem onClick={toContact}>Contact us</MenuItem>
        <MenuItem onClick={toFooter}>Mailing list</MenuItem>
      </Menu>
      <Pitch>
        Paperhat, Limited creates in-house training programs and just-in-time
        curricula using our unique <Link>Paperhat Methodology</Link>, provides
        assistance with talent acquisition and development, and offers speakers
        to discuss modern learning methods.
      </Pitch>
    </SideMenu>
  )
}
