import { navigate } from 'gatsby'
import React from 'react'
import { IconContext } from 'react-icons'
import { FaGlasses, FaGraduationCap, FaUser } from 'react-icons/fa'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'
import Card from '../Card'
import Cards from '../Cards'
import Icon from '../Icon'

const toContact = () => navigate('/#contact')

const Title = styled.h2`
  font-family: 'Brandon Grotesque Medium', Verdana, sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${moonYellow()};
  margin-bottom: 1.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3;
  margin: 3rem 0 1rem;
`

const Subtitle = styled.h3`
  font-family: 'Brandon Grotesque Medium', Verdana, sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${moonYellow()};
  margin-bottom: 1.5rem;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const Description = styled.div`
  font-size: 1.1rem;
  color: ${white()};
  text-align: left;
  flex-grow: 1;
  padding: 0 1rem;
  line-height: 1.4;
`

const Section = styled.section`
  font-family: 'Raleway Thin', Verdana, sans-serif;
  width: 100%;
  background-color: ${grapeGray()};
  padding: 2rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100vh;

  @media (max-width: 1100px) {
    padding: 0.5rem 0;
  }
`

export default function Services () {
  return (
    <Section id='services'>
      <Title>How we can help</Title>
      <Cards>
        <Card onClick={toContact}>
          <Icon>
            <IconContext.Provider value={{ size: '9rem' }}>
              <FaGraduationCap />
            </IconContext.Provider>
          </Icon>
          <Subtitle
            style={{
              color: white(),
              backgroundColor: grapeGray(),
              paddingBottom: '1.5rem'
            }}
          >
            Training
          </Subtitle>
          <Description>
            Leverage our{' '}
            <span style={{ color: moonYellow(), fontWeight: 'bold' }}>
              Paperhat Methodology&trade;
            </span>{' '}
            for powerful and effective in-house or off-site{' '}
            <span style={{ color: moonYellow(), fontWeight: 'bold' }}>
              just-in-time
            </span>{' '}
            learning.
          </Description>
        </Card>
        <Card>
          <Icon>
            <IconContext.Provider value={{ size: '7.5rem' }}>
              <FaGlasses />
            </IconContext.Provider>
          </Icon>
          <Subtitle
            style={{
              color: white(),
              backgroundColor: grapeGray(),
              paddingBottom: '1.5rem'
            }}
          >
            Optimizing
          </Subtitle>
          <Description>
            Let us maximize your{' '}
            <span style={{ color: moonYellow(), fontWeight: 'bold' }}>
              competitiveness
            </span>{' '}
            by minimizing organizational or individual{' '}
            <span style={{ color: moonYellow(), fontWeight: 'bold' }}>
              cognitive footprint
            </span>
            .
          </Description>
        </Card>
        <Card>
          <Icon>
            <IconContext.Provider value={{ size: '6rem' }}>
              <FaUser />
            </IconContext.Provider>
          </Icon>
          <Subtitle
            style={{
              color: white(),
              backgroundColor: grapeGray(),
              paddingBottom: '1.5rem'
            }}
          >
            Speaking
          </Subtitle>
          <Description>
            Engage us to speak to your group about{' '}
            <span style={{ color: moonYellow(), fontWeight: 'bold' }}>
              talent acquisition
            </span>
            ,{' '}
            <span style={{ color: moonYellow(), fontWeight: 'bold' }}>
              team building
            </span>
            ,{' '}
            <span style={{ color: moonYellow(), fontWeight: 'bold' }}>
              state-of-the-art learning
            </span>
            , and more.
          </Description>
        </Card>
      </Cards>
    </Section>
  )
}
