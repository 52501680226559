import React from 'react'
import styled from 'styled-components'

import { grapeGray, moonYellow, white } from '../../constants'

const Title = styled.h3`
  font-family: 'Brandon Grotesque Medium', Verdana, sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${moonYellow()};
  flex-grow: 1;
`

const Message = styled.p`
  font-family: 'Raleway Medium', Verdana, sans-serif;
  font-size: 1.2rem;
  color: ${grapeGray(10)};
  flex-grow: 1;
  margin: 1rem 0;
  text-align: center;
`

const Panel = styled.section`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 auto 2rem;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  background-color: ${white()};
  padding: 2rem;

  @media (min-width: 36rem) {
    align-items: center;
    justify-content: center;
    max-width: 36rem;
  }
`

const Button = styled.button`
  outline: 0;
  border: none;
  font-family: 'Raleway Medium', Verdana, sans-serif;
  font-size: 1.2rem;
  color: ${grapeGray()};
  flex-grow: 1;
  margin: 0.5rem 0;
  text-transform: uppercase;
  border: 1px solid ${grapeGray()};
  padding: 0.5rem;

  &:hover,
  &:focus {
    border-color: ${moonYellow()};
    color: ${moonYellow()};
    box-shadow: 3px 2px 7px ${grapeGray(40)};
  }
`

export default function Thanks ({ resetForm }) {
  return (
    <Panel>
      <Title>Thank you</Title>
      <Message>
        We appreciate your interest in our work.
        <br />
        We will endeavor to respond as soon as possible.
      </Message>
      <Button onClick={resetForm}>Start again</Button>
    </Panel>
  )
}
