import styled from 'styled-components'

import { grapeGray, white } from '../../constants'

const Cards = styled.section`
  text-align: center;
  background-color: ${grapeGray()};
  color: ${white()};
  width: 33%;
  height: 24rem;
  font-size: 3rem;
  cursor: pointer;
  margin: 0 0 2rem;
  min-width: 18rem;
`

export default Cards
