import styled from 'styled-components'

const Cards = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 930px) {
    flex-direction: column;
    align-items: center;
  }
`

export default Cards
